var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.pageLoading)?_c('div',[(_vm.slides && _vm.slides.length > 0)?_c('Slider',{attrs:{"slides":_vm.slides,"auto-play":"","lazy-load":"","add-high-priority-on-first-slide":"","promotion-data":{
      name: 'slider_homepage',
      slot: '1',
      version: _vm.slides[0].creativeVersion,
    }}}):_vm._e(),_vm._v(" "),_c('clientOnly',[(
        _vm.recentlyViewProductGE2 &&
        _vm.$localStorage &&
        _vm.$store.state.ui.deviceType !== 'pc'
      )?_c('RecentlyView',{attrs:{"mobile-style":""}}):_vm._e()],1),_vm._v(" "),(_vm.highlightHomeTop && _vm.highlightHomeTop.length > 0)?_c('HighlightHome',{attrs:{"items":_vm.highlightHomeTop,"promotion-data":{
      name: 'homepage_mise_en_avant_top',
      slot: '2',
      version: _vm.highlightHomeTop[0].creativeVersion,
    }}}):_vm._e(),_vm._v(" "),_c('clientOnly',[(
        _vm.recentlyViewProductGE2 &&
        _vm.$localStorage &&
        _vm.$store.state.ui.deviceType === 'pc'
      )?_c('RecentlyView'):_vm._e()],1),_vm._v(" "),(_vm.highlightedProducts && _vm.highlightedProducts.length > 0)?_c('HighlightProducts',{attrs:{"products":_vm.highlightedProducts,"promotion-data":{
      name: 'top_categorie_homepage',
      slot: '3',
      version: _vm.highlightedProducts[0].creativeVersion,
    }}}):_vm._e(),_vm._v(" "),(_vm.highlightHomeBottom && _vm.highlightHomeBottom.length > 0)?_c('HighlightHome',{attrs:{"items":_vm.highlightHomeBottom,"promotion-data":{
      name: 'homepage_mise_en_avant_bottom',
      slot: '4',
      version: _vm.highlightHomeBottom[0].creativeVersion,
    },"bottom":""}}):_vm._e(),_vm._v(" "),_c('ProductListNosto',{attrs:{"id":"frontpage-nosto-1"}}),_vm._v(" "),(_vm.topSales && _vm.topSales.length > 0)?_c('TopSales',{attrs:{"products":_vm.topSales,"listing-type":"LISTING_TOP_SALES_HOMEPAGE"}}):_vm._e(),_vm._v(" "),(_vm.highlightedStyles && _vm.highlightedStyles.length > 0)?_c('HighlightStyles',{attrs:{"styles":_vm.highlightedStyles,"promotion-data":{
      name: 'style_du_moment_homepage',
      slot: '5',
      version: _vm.highlightedStyles[0].creativeVersion,
    }}}):_vm._e(),_vm._v(" "),_c('ProductListNosto',{attrs:{"id":"frontpage-nosto-2"},on:{"hook:mounted":function($event){return _vm.productListNostoMounted()}}}),_vm._v(" "),(_vm.bestOffers && _vm.bestOffers.length > 0)?_c('BestOffers',{attrs:{"products":_vm.bestOffers,"listing-type":"LISTING_BEST_OFFERS_HOMEPAGE"}}):_vm._e(),_vm._v(" "),(_vm.followUs && _vm.followUs.length > 0)?_c('FollowUs',{attrs:{"items":_vm.followUs,"promotion-data":{
      name: 'wall_instagram',
      slot: '6',
      version: _vm.followUs[0].creativeVersion,
    }}}):_vm._e(),_vm._v(" "),_c('ConnectModal',{attrs:{"type":"HOME"}})],1):_c('Skeleton')
}
var staticRenderFns = []

export { render, staticRenderFns }